export default class app {
  constructor() { 
    const app = document.querySelector(".app");
    if (app) {
      const page = app.dataset.page;
      this.page = page;
    }   
  }

  render() {
    // common funtions
    this.initNav();
    /**
     * IMPORTANT
     * the check on URL PARAM
     * as well as the sessionStorage.setItem() 
     * are done in header.php!!!
     */
    const entries = document.querySelectorAll(".js-entry");
    this.initPagination(entries);

    // page templates
    if (this.page === "home") this.initPageHome();
    if (this.page === "about") this.initPageAbout();
    if (this.page === "news") this.initPageNews();
    if (this.page === "patologie") this.initPagePatologie();
    if (this.page === "testimonianze") this.initPageTestimonianze();
    if (this.page.includes("generic") || this.page.includes("single")) {
      this.initPageSingle();
    }
  }

  /**
   * Common functions:start
   */
  initNav() {
    const btn = document.querySelector(".js-btn-toggle-mobile-nav");
    const navVoiceList = document.querySelector(".menu-header-menu-container");

    if (btn && navVoiceList) {
      btn.addEventListener("click", (e) => {
        btn.classList.toggle("is-open");
        navVoiceList.classList.toggle("is-open");        
      });
    }

    return;
  }

  initPagination(entries) {
    if (entries.length > 10) {
      const entriesPagination = document.createElement("div");
      entriesPagination.classList.add("entries-pagination");
      let countPages = 0;

      // count number of pages to be created
      entries.forEach((entry, i) => {
        // if divisible by 10 increment pagination
        if (i > 9) {
          if (i % 10 === 0) {
            countPages++;
          }

          // hide every entry with index > 9
          if (!entry.classList.contains("is-hidden")) {
            entry.classList.add(`is-hidden`, `js-page-index-${countPages + 1}`);            
          }

        } else {
          entry.classList.add(`js-page-index-1`);
        }
      });

      // create pagination buttons
      while (countPages > 0) {
        this.createPaginationButton(entries, countPages, entriesPagination);        
        countPages--;
      }
      // then create first page button...
      this.createPaginationButton(entries, countPages, entriesPagination); 
      
      // append pagination to entries container
      entries[0].closest(".entries-wrap")?.appendChild(entriesPagination);      
    }

    // check current page param in location and set it in localStorage
    this.checkCurrentPageIndex();  
    return;
  }

  createPaginationButton(entries, countPages, entriesPagination) {
    const pageIndex = document.createElement("button");
    pageIndex.classList.add(`page-index`, `js-page-index-${countPages + 1}`);
    pageIndex.textContent = countPages + 1;

    pageIndex.addEventListener("click", (e) => {
      // remove current class from siblings
      document.querySelector(".page-index.is-current")?.classList.remove("is-current");

      // add current class to target      
      if (!e.target.classList.contains("is-current")) {
        e.target.classList.add("is-current");
        // add current page param to location
        window.history.replaceState({page: countPages + 1}, null, `?page=${countPages + 1}`);
      } 

      // show/hide page entries
      entries.forEach(entry => {
        if (!entry.classList.contains(`js-page-index-${countPages + 1}`)) {
          if (!entry.classList.contains("is-hidden")) entry.classList.add("is-hidden");
        
        } else {
          entry.classList.remove("is-hidden");
        }
      });
    });

    // add current-class to first button
    if (countPages === 0 && !pageIndex.classList.contains("is-current")) pageIndex.classList.add("is-current");

    entriesPagination.appendChild(pageIndex);
  }

  checkCurrentPageIndex() {
    const pageIndex = sessionStorage.getItem("current_page");
    const btnCurrentPage = document.querySelector(".page-index.js-page-index-" + pageIndex);
    btnCurrentPage?.click();

    /**
     * IMPORTANT!!!
     * clean sessionStorage()
     * to avoid pagination init on other locations
     */     
    if (pageIndex) {
      sessionStorage.removeItem("current_page");
    }

    return;
  }
  /**
   * Common functions:end
   */

  /**
   * Home:start
   */
  initPageHome() {   
    // init testimonials swiper
    const swiperTestimonials = document.querySelector(".js-swiper-testimonials");
    this.initSwiperTestimonials(swiperTestimonials);
  }

  initSwiperTestimonials(swiperTestimonials) {
    if (swiperTestimonials) {
      const swiper = new Swiper(".js-swiper-testimonials", {
        slidesPerView: 1.15,
        spaceBetween: 16,
        navigation: {
          nextEl: ".js-btn-next-testimonials",
          prevEl: ".js-btn-prev-testimonials"
        },     
        breakpoints: {
          768: {
            slidesPerView: 2.3,
            spaceBetween: 16
          },
          992: {
            slidesPerView: 3.3,
            spaceBetween: 32 
          },
          1180: {
            slidesPerView: 4.3,
            spaceBetween: 32
          }
        },
      });
    }
    
    return;
  }
  /**
   * Home:end
   */

  /**
   * About:start
   */
  initPageAbout() {
    // init about swiper
    const swiperAbout = document.querySelector(".js-swiper-about");
    this.initSwiperAbout(swiperAbout);
  }

  initSwiperAbout(swiper) {
    if (swiper) {
      const swiper = new Swiper(".js-swiper-about", {
        slidesPerView: 1,
        spaceBetween: 16,
        breakpoints: {
          768: {
            slidesPerView: 2
          }
        },
        navigation: {
          nextEl: ".js-btn-next-about",
          prevEl: ".js-btn-prev-about"
        }                   
      });
    } 
    
    return;
  }
  /**
   * About:end
   */

  /**
   * News:start
   */
  initPageNews() {
    const swiperFeatured = document.querySelector(".js-swiper-featured-news");
    this.initSwiperFeatured(swiperFeatured);
  }

  initSwiperFeatured(swiper) {
    if (swiper) {
      const swiper = new Swiper(".js-swiper-featured-news", {
        slidesPerView: 1.3,
        spaceBetween: 32,
        navigation: {
          nextEl: ".js-btn-next-featured",
          prevEl: ".js-btn-prev-featured"
        },     
        breakpoints: {
          768: {
            slidesPerView: 2.3
          },
          992: {
            slidesPerView: 3.3 
          },
          1280: {
            slidesPerView: 4.3 
          }           
        },        
      });
    }     
    
    return;
  }
  /**
   * News:end
   */

  /**
   * Patologie:start
   */
  initPagePatologie() {
    // const entries = document.querySelectorAll(".js-entry");
    // if (entries.length > 0) {
    //   this.initPagination(entries);
    // }
  }
  /**
   * Patologie:end
   */

  /**
   * Testimonianze:start
   */
  initPageTestimonianze() {
    const entries = document.querySelectorAll(".single-testimonial");
    if (entries.length > 0) {
      // init modal
      this.initTestimonialsModal(entries);
    }

    return;
  }

  initTestimonialsModal(entries) {
    const modal = document.querySelector(".js-modal-full-testimonial");
    if (modal) {
      entries.forEach(entry => {
        const entryImg = entry.querySelector("img");
        const entryName = entry.querySelector("h3");
        const entryText = entry.querySelector(".js-full-desc");
        const entryBtn = entry.querySelector("button");

        const data = {
          entryImgURL: entryImg?.src,
          entryName: entryName?.textContent,
          entryText: entryText?.innerHTML,
        }

        entryBtn?.addEventListener("click", this.openTestimonialsModal.bind(this, data, modal));
      });
    }    

    return;
  }

  openTestimonialsModal(data, modal) {    
    const modalImg = modal.querySelector("img");
    const modalName= modal.querySelector("h3");
    const modalText = modal.querySelector("blockquote");
    const modalCloseBtns = modal.querySelectorAll(".js-modal-close");

    if (modalImg) modalImg.src = data.entryImgURL;
    if (modalName) modalName.textContent = data.entryName;
    if (modalText) modalText.innerHTML = data.entryText;

    // attach close modal events...
    if (modalCloseBtns.length > 0) {
      modalCloseBtns.forEach(button => {
        button.addEventListener("click", this.closeTestimonialsModal.bind(this, modal));
      });
    }

    document.onkeydown = this.closeTestimonialsModal.bind(this, modal);

    if (!modal.classList.contains("is-open")) {
      modal.classList.add("is-open");
      document.querySelector("body").classList.add("overflow-hidden");
    }
  }

  closeTestimonialsModal(modal) {
    if (modal.classList.contains("is-open")) {
      modal.classList.remove("is-open");
      document.querySelector("body").classList.remove("overflow-hidden");
    } 
    if (this.key === "Escape") {
      modal.classList.remove("is-open");
    }
    document.onkeydown = null;
  }
  /**
   * Testimonianze:end
   */

  /**
   * Single:start
   */
  initPageSingle() {
    this.checkPageSingleType();
    // init galleries
    const galleries = document.querySelectorAll(".js-swiper-gallery");
    if (galleries.length > 0) {
      this.initGalleries(galleries);
      // init img lightbox
      this.initGalleriesLightbox(galleries);
    }
  }

  checkPageSingleType() {
    // toggle active class to current menu voicelist on single pages
    if (this.page.includes("type-post")) {
      document.querySelector(".js-news")?.classList.add("current-menu-item");

    } else if (this.page.includes("type-patologie")) {
      document.querySelector(".js-patologie")?.classList.add("current-menu-item");
    }

    return;
  }

  initGalleries(swipers) {
    swipers.forEach((swiper, i) => {
      // initialize each swiper separately
      swiper.classList.add(`js-swiper-gallery-${i}`);
      const btnNext = swiper.querySelector(".swiper-button-next");
      const btnPrev = swiper.querySelector(".swiper-button-prev");

      const gallery = new Swiper(`.js-swiper-gallery-${i}`, {
        slidesPerView: 1,
        spaceBetween: 16,
        navigation: {
          nextEl: btnNext ? btnNext : null,
          prevEl: btnPrev ? btnPrev : null
        }                   
      });

      // remove swiper buttons container there are no images to display
      const imgs = swiper.querySelectorAll("img");
      if (imgs.length === 1) {
        btnNext.closest(".swiper-buttons").remove();        
      }
    });
  }

  initGalleriesLightbox(galleries) {
    galleries.forEach((gallery, i) => {
      const btnsOpenLightbox = gallery.querySelectorAll(".js-img-lightbox");

      if (btnsOpenLightbox.length > 0) {
        btnsOpenLightbox.forEach(button => {
          button.addEventListener("click", (e) => {
            const img = button.closest(".swiper-slide").querySelector("img");
            const imgURL = img.src;

            this.openLightbox(imgURL);
          });
        });
      }
    }); 
  }

  openLightbox(imgURL) {
    const modal = document.querySelector(".js-modal-img-lightbox");
    if (modal) {    
      const modalIMG = modal.querySelector("img");
      const modalClose = modal.querySelector(".js-lightbox-close");
      const modalOverlay = modal.querySelector(".js-lightbox-overlay");

      if (modalIMG) modalIMG.src = imgURL;            

      // attach close lightbox events...
      modalClose?.addEventListener("click", this.closeLightbox.bind(this, modal));
      modalOverlay?.addEventListener("click", this.closeLightbox.bind(this, modal));      
      document.onkeydown = this.closeLightbox.bind(this, modal);
      
      // then show the lightbox
      if (!modal.classList.contains("is-open")) modal.classList.add("is-open");
    }
    
    return;
  }
  
  closeLightbox(modal) {        
    if (modal.classList.contains("is-open")) modal.classList.remove("is-open");
    if (this.key === "Escape") {
      modal.classList.remove("is-open");
    }
    document.onkeydown = null;
  }

  /**
   * Single:end
   */
}